import React from "react"
import Layout from '../components/template/Layout'
import ActionsList from '../components/lists/ActionsList'
import { Container } from '@material-ui/core'

export default function Actions() {
  return (
    <Layout title="Nos Actions" description="Les actions de la Jeune Chambre Economique de Châteaubriant et sa région" type="Liste Actions" image="https://chateaubriant.jcef.asso.fr/static/b6563a053b3907c2c82b4c56278a3648/2a4de/logo.png">
      <Container>
        <ActionsList limit="100"/>
      </Container>
    </Layout>
  );
}

